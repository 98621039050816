import React, {useState, useCallback, useMemo} from 'react';
import {
  Button,
  ButtonGroup,
  TextStyle,
  TextField,
  Icon,
  Stack,
  Popover,
  ChoiceList,
  Select,
  Checkbox
} from '@shopify/polaris';
import {SearchMajor, SortMinor, FilterMajor, ArrowUpMinor, ArrowDownMinor} from '@shopify/polaris-icons';
import {OFFER_FILTERS, OFFER_TYPES_FILTERS} from '../../../../../constants/constants';
import './customIndexFilters.css';

const CustomIndexFilters = ({selectedFilters, setSelectedFilters, sortOption, setSortOption, sortDirection, setSortDirection, searchValue, handleTextFieldChange, selectedOffersFilters, setSelectedOffersFilters}) => {
  const [sortPopoverActive, setSortPopoverActive] = useState(false);
  const [searchPopoverActive, setSearchPopoverActive] = useState(false);
  const [offersSortPopoverActive, setOffersSortPopoverActive] = useState(false);
  const [offersSortSideBarActive, setOffersSortSideBarActive] = useState(false);

  const handleSortChange = useCallback((value) => setSortOption(value[0]), [setSortOption]);
  const handleDirectionChange = useCallback((value) => setSortDirection(value[0]), [setSortDirection]);

  const handleSelectFilter = useCallback((filterTitle) => {
    setSelectedFilters(filterTitle);
  }, [setSelectedFilters]);

  const handleOfferSortChange = useCallback((value, type) => {
     if (value) {
       setSelectedOffersFilters((prevState) => [...prevState, type]);
     } else {
       setSelectedOffersFilters((prevState) => prevState.filter((i) => i !== type));
     }
  }, []);

  /** Turns on/off Sort Popover */
  const toggleSortPopoverActive = useCallback(
    () => setSortPopoverActive((popoverActive) => !popoverActive),
    [setSortPopoverActive],
  );

  /** Turns on/off Search Popover */
  const toggleSearchPopoverActive = useCallback(
    () => setSearchPopoverActive((popoverActive) => !popoverActive),
    [setSearchPopoverActive],
  );

  /** Turns on/off Offers sort Popover */
  const toggleOffersSortPopoverActive = useCallback(
    () => setOffersSortPopoverActive((popoverActive) => !popoverActive),
    [setOffersSortPopoverActive],
  );

  /** Turns on/off Offers sort side bar */
  const toggleOffersSortSideBarActive = useCallback(
    () => setOffersSortSideBarActive((sideBarActive) => !sideBarActive),
    [setOffersSortSideBarActive],
  );

  const renderFiltersButtons = useMemo(() => {
    return OFFER_FILTERS.map((filterTitle, index) => {
      return (
        <div key={index} className={selectedFilters === filterTitle ? 'FiltersButtonsContainer ActiveFilter' : 'FiltersButtonsContainer'}>
          <Button plain removeUnderline onClick={() => handleSelectFilter(filterTitle)} disclosure={selectedFilters === filterTitle && 'down'}>
            <Stack alignment='center' spacing='extraTight'>
              <TextStyle variation='subdued' size='large'>
                {filterTitle}
              </TextStyle>
            </Stack>
          </Button>
        </div>
      )
    })
  }, [selectedFilters]);

  const renderOfferTypes = useMemo(() => {
    return OFFER_TYPES_FILTERS.map((type) => {
      return (
        <Checkbox
          label={type}
          checked={selectedOffersFilters.includes(type)}
          onChange={(value) => handleOfferSortChange(value, type)}
        />
      )
    })
  }, [selectedOffersFilters]);

  const sortActivator = (
    <Button onClick={toggleSortPopoverActive}>
      <Icon
        source={SortMinor}
        color='subdued'
      />
    </Button>
  );

  const searchActivator = (
    <Button onClick={toggleSearchPopoverActive}>
      <Stack spacing='extraTight'>
        <Icon
          source={SearchMajor}
          color='subdued'
        />
        <Icon
          source={FilterMajor}
          color='subdued'
        />
      </Stack>
    </Button>
  );

  const offersSortActivator = useMemo(() => {
    const title = (Array.isArray(selectedOffersFilters) && selectedOffersFilters.length > 0)
      ? `Offer type is ${selectedOffersFilters.join(', ')}`
      : 'Offer type'

    return (
      <Button textAlign='center' disclosure='down' onClick={toggleOffersSortPopoverActive}>
        <TextStyle variation='subdued'>{title}</TextStyle>
      </Button>
    )
  }, [toggleOffersSortPopoverActive, selectedOffersFilters]);

  const offersSortToggle = useMemo(() => {
    if (offersSortSideBarActive) {
      return <Button plain onClick={toggleOffersSortSideBarActive}>Cancel</Button>;
    } else {
      return (
        <Button onClick={toggleOffersSortSideBarActive}>
          <Stack spacing='extraTight'>
            <Icon
              source={SearchMajor}
              color='subdued'
            />
            <Icon
              source={FilterMajor}
              color='subdued'
            />
          </Stack>
        </Button>
      )
    }
  }, [offersSortSideBarActive, toggleOffersSortSideBarActive])

  const sortChoices = [
    {label: 'None', value: 'none'},
    {label: 'Name', value: 'name'},
    {label: 'Impressions', value: 'impressions'},
    {label: 'Revenue', value:'revenue'},
    {label: 'Conversion', value: 'conversion'},
  ];

  const directionChoices = [
    {
      label: <Stack><Icon source={ArrowUpMinor} color={(sortOption.length && sortOption[0] === 'none') && 'subdued'}/><TextStyle>Asc</TextStyle></Stack>,
      value: 'asc'},
    {
      label: <Stack><Icon source={ArrowDownMinor} color={(sortOption.length && sortOption[0] === 'none') && 'subdued'}/><TextStyle>Desc</TextStyle></Stack>,
      value: 'desc'},
  ];

  return (
    <div>
      <div className='CustomFiltersContainer'>
        <ButtonGroup>
          {renderFiltersButtons}
        </ButtonGroup>

        <ButtonGroup>
          {/*<div onClick={(e) => e.stopPropagation()}>*/}
          {/*  <Popover*/}
          {/*    active={searchPopoverActive}*/}
          {/*    activator={searchActivator}*/}
          {/*    onClose={() => toggleSearchPopoverActive()}*/}
          {/*    preferredAlignment='right'*/}
          {/*    preferredPosition='below'*/}
          {/*  >*/}
          {/*    <Popover.Pane fixed>*/}
          {/*      <Popover.Section>*/}
          {/*        <p>Search by name</p>*/}
          {/*      </Popover.Section>*/}
          {/*    </Popover.Pane>*/}
          {/*    <Popover.Pane>*/}
          {/*      <Popover.Section>*/}
          {/*        <TextField*/}
          {/*          label="Offer name"*/}
          {/*          labelHidden*/}
          {/*          value={searchValue}*/}
          {/*          onChange={handleTextFieldChange}*/}
          {/*          placeholder="Offer name"*/}
          {/*          autoComplete="off"*/}
          {/*        />*/}
          {/*      </Popover.Section>*/}
          {/*    </Popover.Pane>*/}
          {/*  </Popover>*/}
          {/*</div>*/}

          {offersSortToggle}

          <div onClick={(e) => e.stopPropagation()}>
            <Popover
              active={sortPopoverActive}
              activator={sortActivator}
              onClose={() => toggleSortPopoverActive()}
              autofocusTarget="first-node"
              preferredAlignment='right'
              preferredPosition='below'
            >
              <Popover.Pane fixed>
                <Popover.Section>
                  <p>Sort by</p>
                </Popover.Section>
              </Popover.Pane>
              <Popover.Pane>
                <Popover.Section>
                  <ChoiceList
                    title="Company name"
                    titleHidden
                    choices={sortChoices}
                    selected={sortOption}
                    onChange={handleSortChange}
                  />
                </Popover.Section>
              </Popover.Pane>
              <Popover.Pane fixed>
                <Popover.Section>
                  <ChoiceList
                    title="Direction"
                    choices={directionChoices}
                    selected={sortDirection}
                    onChange={handleDirectionChange}
                    disabled={sortOption.length && sortOption[0] === 'none'}
                  />
                </Popover.Section>
              </Popover.Pane>
            </Popover>
          </div>
        </ButtonGroup>
      </div>

      {
        offersSortSideBarActive &&
          <div className='OfferFilterBar'>
            <Popover
              active={offersSortPopoverActive}
              activator={offersSortActivator}
              onClose={() => toggleOffersSortPopoverActive()}
              preferredAlignment='left'
              preferredPosition='below'
            >
              <Popover.Pane>
                <Popover.Section>
                  <div style={{width: '155px'}}>
                    <Stack vertical spacing='tight'>
                      {renderOfferTypes}
                    </Stack>
                  </div>
                </Popover.Section>
              </Popover.Pane>
            </Popover>
          </div>
      }
    </div>
  );
};

export default CustomIndexFilters;